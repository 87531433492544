<template>
  <div>
    <h1>{{ $t('forgotPassword.submitCode.title') }}</h1>
    <p v-html="$t('forgotPassword.submitCode.desc', { email })" />

    <v-form
      @submit.prevent="submit"
      v-model="isFormValid"
    >
      <v-row
        dense
      >
        <v-text-field
          class="centered code"
          v-model="code"
          v-mask="'#-#-#-#-#-#'"
          placeholder="#-#-#-#-#-#"
          :rules="codeRules"
          outlined
          validate-on-blur
          ref="code"
          @input="checkValidation('code')"
        />
      </v-row>

      <password
        v-if="code.length === 11"
        v-model="password"
        :rules="rules.password"
        validate-on-blur
        ref="password"
        @input="checkValidation('password', true)"
        label="forgotPassword.submitCode.newPassword"
      ></password>

      <password
        v-if="code.length === 11"
        v-model="retypePassword"
        :rules="samePassword"
        validate-on-blur
        ref="retypePassword"
        @input="checkValidation('retypePassword', true)"
        label="forgotPassword.submitCode.retypePassword"
      ></password>

      <div
        class="text-decoration-underline primary--text cursor-pointer"
        @click="requestCode"
      >
        <span class="position-relative">
          {{ $t('forgotPassword.submitCode.resend') }}
          <div v-if="loading === 'request'" class="resend-disable backgroundDark" />
        </span>
      </div>

      <v-btn
        large block
        color="primary"
        type="submit"
        class="mt-5"
        :loading="loading === 'submit'"
        :disabled="!isFormValid || loading === 'request'"
      >
        {{ $t('forgotPassword.submitCode.submit') }}
      </v-btn>
    </v-form>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { mask } from 'vue-the-mask';
import { password } from '@/utils/validators';
import Password from '@/components/Shared/InputFields/Password.vue';

export default {
  name: 'ForgotPasswordSubmitCode',
  components: {
    Password,
  },
  directives: {
    mask,
  },
  data() {
    return {
      loading: null,
      code: '',
      password: '',
      retypePassword: '',
      codeRules: [
        (v) => (v && v.split('-').join('').length === 6),
      ],
      rules: {
        password,
      },
      samePassword: [],
      numberOfClicks: 0,
    };
  },
  computed: {
    ...mapGetters('forgotPassword', [
      'email',
    ]),
    ...mapFields('registration', [
      'isFormValid',
    ]),
  },
  watch: {
    password(newVal) {
      this.samePassword = [
        (v) => v === newVal || this.$t('forgotPassword.submitCode.passwordError'),
      ];
    },
  },
  methods: {
    async submit() {
      this.numberOfClicks += 1;
      this.loading = 'submit';
      const code = this.code.split('-').join('');
      try {
        await this.$store.dispatch('forgotPassword/newPassword', {
          email: this.email,
          newPassword: this.password,
          verificationCode: code,
        });
        this.$router.push({ name: 'SignIn' });
      } catch (error) {
        if (this.numberOfClicks === 5) {
          this.$notify.error(this.$t('forgotPassword.submitCode.error'));
          this.$router.push({ name: 'SignIn' });
          return;
        }
        this.$notify.error(error);
      } finally {
        this.loading = null;
      }
    },
    async requestCode() {
      this.loading = 'request';
      try {
        await this.$store.dispatch('forgotPassword/requestCode', this.email);
        this.$notify.success(this.$t('forgotPassword.cognitoMessages.CodeRequestedSucessfully'));
      } catch (error) {
        this.$notify.error(error);
      } finally {
        this.loading = null;
      }
    },
    checkValidation(refName, children = false) {
      if (!this.isFormValid) {
        if (children) {
          this.$refs[refName].$children[0].validate();
        } else {
          this.$refs[refName].validate();
        }
      }
    },
  },
};
</script>

<style scoped>
.centered >>> input {
  text-align: center;
  font-size: 2em;
  font-weight: 200;
  max-height: 42px !important;
  padding: 0 !important;
}

.resend {
  padding-top: 3em;
}

.hide-password {
  visibility: 'hidden';
}

.resend-disable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0.4;
}
</style>
